<template>
    <div class="bar-menu-dashboard-box">

        <div v-if="!desktop"  class="res-show tgcss-hidden lg:tgcss-flex tgcss-h-full tgcss-group  tgcss-text-gray-700 dark:tgcss-text-white"  :class="`${menu == 0 ? 'is-minify' : 'is-full'}`">

            <div  class="sb-bg-dark tgcss-flex tgcss-relative tgcss-flex-col tgcss-h-full tgcss-bg-white tgcss-w-14 group-[.is-full]:tgcss-w-72 tgcss-border-l tgcss-border-gray-200">
                

                <div class="tgcss-p-[10px] tgcss-pb-1 tgcss-cursor-pointer group-[.is-full]:tgcss-hidden">
                    <div v-if="$store.state.user" class="tgcss-overflow-hidden tgcss-rounded-full">
                        <img :src="$store.state.user.avatar ? ($helpers.api_url() + $store.state.user.avatar.name) : 'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
                    </div>
                </div>


                <div class="sidebar-user-bg tgcss-p-[25px] tgcss-pb-1 group-[.is-minify]:tgcss-hidden ">
                    <img class="sidebar-user-bg-cover" :src="$store.state.user.opts.header_cover.name ? ($helpers.api_url() + $store.state.user.opts.header_cover.name) : 'https://static.tgju.org/views/default/images/tgju/dash-header-bd.png'">

                    <img class="tgcss-overflow-hidden  tgcss-relative tgcss-z-10 tgcss-w-14 tgcss-border-4 tgcss-border-white  tgcss-rounded-full group-[.is-full]:md:tgcss-mr-3" :src="$store.state.user.avatar ? ($helpers.api_url() + $store.state.user.avatar.name) : 'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
                    <div v-if="$store.state.user"  class="tgcss-flex tgcss-py-3 tgcss-relative tgcss-z-10 group-[.is-full]:md:tgcss-pr-3">
                        <div class="tgcss-flex-grow tgcss-text-sm tgcss-font-semibold tgcss-truncate dark-text-white">{{ $store.state.user.firstname +'  '+ $store.state.user.lastname }}</div>
                        <div class="tgcss-pr-2">

                            <router-link v-if="!$store.state.user" :to="{ name: 'Login' }" class="tgcss-flex tgcss-items-center tgcss-bg-[#e63946] hover:tgcss-bg-[#da4350ef] !tgcss-text-white tgcss-h-6 tgcss-px-2 tgcss-rounded-full">
                                <svg class="tgcss-w-4 tgcss-h-4" data-slot="icon" aria-hidden="true" fill="none" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                <a href="" class="tgcss-pb-1 tgcss-mr-1 !tgcss-text-white">ورود - عضویت</a>
                            </router-link>

                            <router-link v-if="$store.state.user" :to="{ name: 'Logout' }" class="tgcss-flex tgcss-items-center tgcss-bg-[#e63946] hover:tgcss-bg-[#da4350ef] !tgcss-text-white tgcss-h-6 tgcss-px-2 tgcss-rounded-full">
                                <svg class="tgcss-w-4 tgcss-h-4" data-slot="icon" aria-hidden="true" fill="none" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                <a href="" class="tgcss-pb-1 tgcss-mr-1 !tgcss-text-white">خروج</a>
                            </router-link>

                        </div>
                    </div>
                </div>


                <div class="tgcss-divide-y tgcss-divide-gray-200">
                    <div v-for="(item, index) in sidebar_items" :key="item.routeName" >
                        <router-link :disabled="item.children.length" :event="!item.children.length ? 'click' : ''" :to="{ name: item.routeName, params: item.routeParams }" @click.native="activeNavToggle(index,item)" :data-target="item.routeName"  :class="{ 'tgcss-bg-gray-100 !tgcss-text-gray-700': activeNav == index }" class="li-dark-style tgcss-flex tgcss-h-12 tgcss-items-center group-[.is-minify]:tgcss-p-[14px] hover:tgcss-text-gray-700 hover:tgcss-bg-gray-100 tgcss-cursor-pointer group-[.is-full]:tgcss-px-6 group-[.is-minify]:tgcss-px-4 group-[.is-full]:tgcss-py-2 group-[.is-full]:md:tgcss-pr-9">
                            <div v-html="item.svg_h"></div>
                            <span class="tgcss-text-sm tgcss-font-semibold tgcss-pb-[1px] group-[.is-minify]:tgcss-hidden" >{{ item.title }}</span>
                        </router-link>
                    </div>
                </div>

                <div class="tgcss-flex-grow"></div>
                
                <div v-if="activeNavData == null" @click="menu = 0;subMenu = 0;" class="group-[.is-minify]:tgcss-hidden tgcss-absolute tgcss-top-1 tgcss-z-10 tgcss-flex tgcss-items-center tgcss-h-6 tgcss-cursor-pointer tgcss-w-4 tgcss-left-0 tgcss-text-gray-500 tgcss-bg-gray-200 tgcss-rounded-r-md">
                    <svg data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m8.25 4.5 7.5 7.5-7.5 7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>
                <div v-if="activeNavData != null" @click="menu = 0;subMenu = 1;" class="group-[.is-minify]:tgcss-hidden tgcss-absolute tgcss-top-1 tgcss-z-10 tgcss-flex tgcss-items-center tgcss-h-6 tgcss-cursor-pointer tgcss-w-4 tgcss-left-0 tgcss-text-gray-500 tgcss-bg-gray-200 tgcss-rounded-r-md">
                    <svg data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m8.25 4.5 7.5 7.5-7.5 7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>

                
                <div v-if="activeNavData != null && subMenu == 1" @click="menu = 1;subMenu = 0;" class="group-[.is-full]:tgcss-hidden tgcss-absolute tgcss-top-1 tgcss-z-10 tgcss-flex tgcss-items-center tgcss-h-6 tgcss-cursor-pointer tgcss-w-4 -tgcss-left-4 tgcss-text-gray-500 tgcss-bg-gray-200 tgcss-rounded-l-md">
                    <svg data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 19.5 8.25 12l7.5-7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>
                <div v-if="activeNavData != null && subMenu == 0" @click="menu = 0;subMenu = 1;" class="group-[.is-full]:tgcss-hidden tgcss-absolute tgcss-top-1 tgcss-z-10 tgcss-flex tgcss-items-center tgcss-h-6 tgcss-cursor-pointer tgcss-w-4 -tgcss-left-4 tgcss-text-gray-500 tgcss-bg-gray-200 tgcss-rounded-l-md">
                    <svg data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 19.5 8.25 12l7.5-7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>
                <div v-if="activeNavData == null && subMenu == 1 || activeNavData == null && subMenu == 0" @click="menu = 1;" class="group-[.is-full]:tgcss-hidden tgcss-absolute tgcss-top-1 tgcss-z-10 tgcss-flex tgcss-items-center tgcss-h-6 tgcss-cursor-pointer tgcss-w-4 -tgcss-left-4 tgcss-text-gray-500 tgcss-bg-gray-200 tgcss-rounded-l-md">
                    <svg data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 19.5 8.25 12l7.5-7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>

            </div>

            <div v-if="activeNavData != null && subMenu == 1"  class="group-[.is-full]:tgcss-hidden tgcss-h-full tgcss-relative tgcss-py-3 tgcss-pt-6 tgcss-bg-white tgcss-w-72 tgcss-border-l tgcss-border-gray-200">
                
                

                <div class="tgcss-text-xs tgcss-font-bold tgcss-pt-[15px] tgcss-p-[9px] tgcss-px-3 tgcss-opacity-60">
                    <div class="tgcss-pt-[10px] tgcss-h-[30px] tgcss-mb-1" v-html="activeNavData.svg_h"></div>
                    {{activeNavData.title}}
                </div>

                

                <!-- <div v-if="activeNavData.widget" class="tgcss-border tgcss-border-gray-200 tgcss-p-3  tgcss-rounded-md tgcss-mx-3 tgcss-mb-3">
                    <div class="tgcss-flex tgcss-items-center tgcss-mb-3">
                        <h3 class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow">دارایی‌ها</h3>
                        <div class="tgcss-flex tgcss-items-center tgcss-bg-gray-100 tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-cursor-pointer">
                            <div class="tgcss-rounded-md tgcss-bg-white tgcss-p-0.5 tgcss-px-2 tgcss-pb-1">تومان</div>
                            <div class="tgcss-rounded-md tgcss-p-0.5 tgcss-px-2">USDT</div>
                        </div>
                    </div>
                    <div class="tgcss-flex tgcss-items-center tgcss-font-semibold">
                        <div class="tgcss-flex-grow tgcss-font-semibold tgcss-text-sm tgcss-text-gray-700">کیف‌پول : </div>
                        <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex tgcss-items-center">
                            <span class="tgcss-text-base">174,794,064</span>
                            <span class="tgcss-mr-1 tgcss-text-xs tgcss-opacity-70">تومان</span>
                        </div>
                    </div>
                    <div class="tgcss-flex tgcss-items-center tgcss-font-semibold tgcss-mt-1 tgcss-mb-[26px]">
                        <div class="tgcss-flex-grow tgcss-font-semibold tgcss-text-xss tgcss-text-gray-700 tgcss-opacity-80">تغییر در ۲۴ ساعت</div>
                        <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex tgcss-items-center tgcss-text-red-400">
                            <span class="tgcss-text-xss">1,774,035-</span>
                            <span class="tgcss-mr-1 tgcss-text-xss tgcss-opacity-70">تومان</span>
                        </div>
                    </div>
                    <div class="tgcss-w-full tgcss-grid tgcss-gap-3 tgcss-grid-cols-2 tgcss-font-semibold tgcss-text-sm tgcss-mt-5">
                        <router-link :to="{ name: 'ExchangeWalletDeposit' }" :data-target="'ExchangeWalletDeposit'"  class="!tgcss-bg-[#f8931d] !tgcss-text-center !tgcss-no-underline !tgcss-text-white tgcss-font-bold !tgcss-pt-2 !tgcss-pb-3 !tgcss-px-4 !tgcss-rounded-md">
                            واریز
                        </router-link>
                        <router-link :to="{ name: 'ExchangeWalletWithdraw' }" :data-target="'ExchangeWalletWithdraw'"  class="!tgcss-bg-[#f4f5f7] !tgcss-text-center !tgcss-no-underline hover:!tgcss-bg-[#f8931d] !tgcss-text-gray-700 !tgcss-font-semibold hover:!tgcss-text-white !tgcss-pt-2 !tgcss-pb-3 !tgcss-px-4 !tgcss-rounded-md">
                            برداشت
                        </router-link>
                    </div>
                </div> -->

                <ul class="tgcss-p-0 tgcss-m-0 tgcss-border-t -tgcss-mt-[1px] tgcss-w-full tgcss-divide-y tgcss-divide-gray-200">

                    <li v-for="(item, index) in activeNavData.children" :key="item.routeName" >
                        <router-link :to="{ name: item.routeName, params: item.routeParams }" @click.native="activeSubNavToggle(index,item)" :data-target="item.routeName"  :class="{ 'tgcss-bg-gray-100 !tgcss-text-gray-700': activeSubNav == index }" class="tgcss-flex tgcss-h-12 tgcss-items-center group-[.is-minify]:tgcss-p-[14px] hover:tgcss-text-gray-700 hover:tgcss-bg-gray-100 tgcss-cursor-pointer group-[.is-full]:tgcss-px-6 group-[.is-minify]:tgcss-px-4 group-[.is-full]:tgcss-py-2">
                            <div v-html="item.svg_h"></div>
                            <span class="tgcss-text-sm tgcss-font-semibold tgcss-pb-[1px]" >{{ item.title }}</span>
                        </router-link>
                    </li>
                </ul>

                <div @click="subMenu = 0,menu = 0" class="tgcss-absolute tgcss-top-1 tgcss-z-10 tgcss-flex tgcss-items-center tgcss-h-6 tgcss-cursor-pointer tgcss-w-4 tgcss-left-0 tgcss-text-gray-500 tgcss-bg-gray-200 tgcss-rounded-r-md">
                    <svg data-slot="icon" aria-hidden="true" fill="none" stroke-width="3" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="m8.25 4.5 7.5 7.5-7.5 7.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </div>
            </div>
        </div>


        <div v-if="desktop" class="tgcss-h-full">
            <div class="bar-menu-bg"></div>
            <div class="bar-menu-desktop-res" @click="miniSidebar()"></div>    
            <div v-if="$store.state.user" class="bar-menu-dashboard show res-show tgcss-h-full" data-target="right-menu">
                <div class="user-sidebar-profile bootstrap-fix">
                    <div v-if="desktop" class="sidebar-desktop-top">
                        <div class="desktop-menu-toggle">
                            <i class="uil uil-bars" @click="miniSidebar()"></i>
                        </div>
                        <div class="sidebar-desktop-top-btns">
                            <router-link v-if="$store.state.user" :to="{ name: 'AlertsView' }">
                                <i class="uil uil-bell">
                                    <!-- <span class="count">{{alertsCount}}</span> -->
                                </i>
                            </router-link>
        
                            <router-link v-if="$store.state.user" :to="{ name: 'ProfileEdit' }">
                                <i class="uil uil-setting"></i>
                            </router-link>
                        </div>
                    </div>
                    <div class="bar-user-cover">
                        <!-- <div class="demo-tag"><span>آزمایشی</span></div> -->
                        <div class="bar-user-cover-bg">
                            <img :src="$store.state.user.opts.header_cover.name ? ($helpers.api_url() + $store.state.user.opts.header_cover.name) : 'https://static.tgju.org/views/default/images/tgju/dash-header-bd.png'">
                        </div>
                        <div class="bar-user-cover-info">
                            <div v-if="$store.state.user" class="bar-user-cover-info-avatar">
                                <slot v-if="desktop">
                                    <i v-if="!$store.state.user.avatar" class="uil uil-user-circle" style="font-size: 26px;line-height: 0;" @click="miniSidebar()"></i>
                                    <img v-else :src="$store.state.user.avatar ? ($helpers.api_url() + $store.state.user.avatar.name) : 'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
                                </slot>
                                <slot v-else>
                                    <img :src="$store.state.user.avatar ? ($helpers.api_url() + $store.state.user.avatar.name) : 'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
                                </slot>
                            </div>
                            <div v-if="$store.state.user" class="user-name">
                                <span class="user-sidebar-profile-name">
                                    {{ $store.state.user.firstname +'  '+ $store.state.user.lastname }}
                                </span>
                                <router-link v-if="!$store.state.user" :to="{ name: 'Login' }" class="btn btn-danger btn-round sidebar-logout-btn auth-btn">
                                    ورود - عضویت
                                </router-link>
                                <router-link v-if="$store.state.user" :to="{ name: 'Logout' }" class="btn btn-danger btn-round sidebar-logout-btn">
                                    خروج
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="user-sidebar-profile-list-title">منـوها</div>-->

                <ul v-if="desktop" class="user-sidebar-profile-list">
                    <li v-for="(item, index) in sidebar_items_desktop" :key="item.routeName" :class="{ 'active-nav': activeNav == index , 'has-children': item.children.length }">
                        <router-link :disabled="item.children.length" :event="!item.children.length ? 'click' : ''" :to="{ name: item.routeName, params: item.routeParams }" class="sub-menu-title-first" @click.native="activeNavToggle(index)" :data-target="item.routeName">
                            <slot v-if="item.icon">
                                <i :class="item.icon"></i>
                            </slot>
                            <span class="bar-menu-sub-title">{{ item.title }}</span>
                            <span class="bullet"></span>
                        </router-link>
                        <ul v-if="item.children.length">
                            <li v-for="(child, index) in item.children" @click="activeSubNavToggle(index)" :class="{ 'active-subnav': activeSubNav == index }" :key="child.routeName + child.routeParams.id">
                                <router-link :to="{ name: child.routeName, params: child.routeParams }">
                                    <i class="uil uil-angle-left"></i>
                                    <span class="bar-menu-sub-title">{{ child.title }}</span>
                                    <span class="bullet"></span>
                                </router-link>
                            </li>
                        </ul>
                        <div class="division-bar"></div>
                    </li>
                </ul>
        
                <ul  v-if="!desktop" class="user-sidebar-profile-list">
                    <li v-for="(item, index) in sidebar_items" :key="item.routeName" :class="{ 'active-nav': activeNav == index , 'has-children': item.children.length }">
                        <router-link :disabled="item.children.length" :event="!item.children.length ? 'click' : ''" :to="{ name: item.routeName, params: item.routeParams }" class="sub-menu-title-first" @click.native="activeNavToggle(index)" :data-target="item.routeName">
                            
                            <slot v-if="item.icon && desktop">
                                <i :class="item.icon"></i>
                            </slot>
        
                            <div v-if="!desktop" v-html="item.svg_h"></div>
        
                            <!-- <svg v-if="item.svg" class="sidebar-icon-svg" width="25" height="20">
                                <use :xlink:href="$helpers.client_url()+'/img/spriteuse.svg#'+item.svg" />
                            </svg> -->
                            <span class="bar-menu-sub-title">{{ item.title }}</span>
                            <span class="bullet"></span>
                        </router-link>
                        <ul v-if="item.children.length">
                            <li v-for="(child, index) in item.children" @click="activeSubNavToggle(index)" :class="{ 'active-subnav': activeSubNav == index }" :key="child.routeName + child.routeParams.id">
                                <router-link :to="{ name: child.routeName, params: child.routeParams }">
                                    <i class="uil uil-angle-left"></i>
                                    <span class="bar-menu-sub-title">{{ child.title }}</span>
                                    <span class="bullet"></span>
                                </router-link>
                            </li>
                        </ul>
                        <div class="division-bar"></div>
                    </li>
                </ul>
                <!--<ul v-html="$store.state.sidebar"></ul>-->
            </div>

        </div>
    </div>
</template>

<script>
// این وویو لی اوت منوی جنبی می باشد
import ClickOutside from 'vue-click-outside';
export default {
    name: 'RightNav',
    components: {},
    data: function () {
        return {
            sidebar_items_desktop: [{
                    title: 'خانـه',
                    routeName: 'HomeIntro',
                    icon: 'uil uil-estate',
                    children: [],
                },{
                    title: 'بازارها',
                    routeName: 'MarketRedirect',
                    icon: 'uil uil-shop',
                    children: [
                            {
                                title: 'بازارهای داخلی',
                                routeName: 'MarketRedirect',
                                routeParams: "/",
                                icon: 'uil uil-university',
                            },
                            {
                                title: 'بازارهای جهانی',
                                routeName: 'MarketRedirect',
                                routeParams: "/",
                                icon: 'uil uil-university',
                            },
                        ],
                },
                // {
                //     title: 'معاملات',
                //     routeName: 'ExchangeDashboard',
                //     icon: 'uil uil-create-dashboard',
                //     children: [
                //             {
                //                 title: 'داشبورد',
                //                 routeName: 'ExchangeDashboard',
                //                 routeParams: "/",
                //                 icon: 'uil uil-university',
                //             },
                //             {
                //                 title: 'خرید',
                //                 routeName: 'ExchangeBuy',
                //                 routeParams: "/",
                //                 icon: 'uil uil-university',
                //             },
                //             {
                //                 title: 'فروش',
                //                 routeName: 'ExchangeSell',
                //                 routeParams: "/",
                //                 icon: 'uil uil-university',
                //             },
                //             {
                //                 title: 'بازار',
                //                 routeName: 'ExchangeTrade',
                //                 routeParams: "/",
                //                 icon: 'uil uil-university',
                //             },
                //             {
                //                 title: 'کیف‌پول',
                //                 routeName: 'ExchangeWallet',
                //                 routeParams: "/",
                //                 icon: 'uil uil-university',
                //             },
                //             {
                //                 title: 'اعلانات',
                //                 routeName: 'ExchangeNotifications',
                //                 routeParams: "/",
                //                 icon: 'uil uil-university',
                //             },
                //             {
                //                 title: 'پشتیبانی',
                //                 routeName: 'ExchangeTicket',
                //                 routeParams: "/",
                //                 icon: 'uil uil-university',
                //             },
                //             {
                //                 title: 'پروفایل',
                //                 routeName: 'ExchangeProfile',
                //                 routeParams: "/",
                //                 icon: 'uil uil-university',
                //             },
                //         ],
                // },
                {
                    title: 'صرافی‌ها',
                    routeName: 'CurrencyExchange',
                    icon: 'uil uil-university',
                    children: [
                            {
                                title: 'صرافی‌های ارزی',
                                routeName: 'CurrencyExchange',
                                routeParams: "/",
                                icon: 'uil uil-university',
                            },
                            {
                                title: 'صرافی‌های داخلی‌کریبتو',
                                routeName: 'LocalExchanges',
                                routeParams: "/",
                                icon: 'uil uil-university',
                            },
                            {
                                title: 'صرافی‌های جهانی‌کریبتو',
                                routeName: 'GlobalExchanges',
                                routeParams: "/",
                                icon: 'uil uil-university',
                            },
                        ],
                },{
                    title: 'شاخص‌ها',
                    routeName: 'CurrencyExchange',
                    icon: 'uil uil-globe',
                    children: [
                            {
                                title: 'شاخص‌ها',
                                routeName: 'EconomicsCountryListCategory',
                                routeParams: "/",
                                icon: 'uil uil-globe',
                            },
                            {
                                title: 'صرافی‌های کشورها',
                                routeName: 'EconomicsAtlas',
                                routeParams: "/",
                                icon: 'uil uil-globe',
                            },
                        ],
                },{
                    title: 'ابزارها',
                    routeName: 'ToolsIntro',
                    icon: 'uil uil-apps',
                    children: [
                            {title: 'هشدارها',routeName: 'AlertsView',routeParams: "/",icon: 'uil uil-bell'},
                            {title: 'واچ‌لیست‌ها',routeName: 'WatchListsView',routeParams: "/",icon: 'uil uil-list-ui-alt'},
                            {title: 'وظایف روزانه',routeName: 'TodoView',routeParams: "/",icon: 'uil uil-list-ol-alt'},
                            {title: 'یادداشت‌ها و اسناد',routeName: 'NotesView',routeParams: "/",icon: 'uil uil-notes'},
                            {title: 'سایر ابزارها',routeName: 'ToolsIntro',routeParams: "/",icon: 'uil uil-apps'},
                        ],
                },
                {title: 'اخبـار',routeName: 'NewsIntro',icon: 'uil uil-newspaper',children: []},
                {title: 'گفت‌و‌گوها',routeName: 'Forums',icon: 'uil uil-comments-alt',children: []},
                {title: 'تحلیل‌ها',routeName: 'NewTechnical',icon: 'uil uil-chart',children: []},
                {title: 'داشبورد‌ها',routeName: 'Dashboards',icon: 'uil uil-create-dashboard',children: []},
                {title: 'سبد دارایی',routeName: 'Investment',icon: 'uil uil-bill',children: []},
                {title: 'حساب کاربری',routeName: 'ProfileEdit',icon: 'uil uil-user-square',children: [
                    {title: 'نمایه کاربر',routeName: 'Profile',routeParams: "/",icon: 'uil uil-shop'},
                    {title: 'حساب کاربری',routeName: 'ProfileEdit',routeParams: "/",icon: 'uil uil-university'},
                ]},

    
                {title: 'سایر',routeName: 'ToolsIntro',icon: 'uil uil-ellipsis-h',children: [
                    {title: 'پشتیبانی هوشمند',routeName: 'SmartChat',routeParams: "/",icon: 'uil uil-comment-dots'},
                    {title: 'پشتیبانی آنلاین',routeName: 'Chat',routeParams: "/",icon: 'uil uil-comments'},
                    {title: 'پخش‌های زنده',routeName: 'Stream',routeParams: "/",icon: 'uil uil-tv-retro'},
                    {title: 'ربات‌های پاسخگو',routeName: 'Robot',routeParams: "/",icon: 'uil uil-robot'},
                    {title: 'شبکه‌های اجتماعی',routeName: 'Social',routeParams: "/",icon: 'uil uil-image-share'},
                    {title: 'افزونه‌های تلگرام',routeName: 'Social',routeParams: "/",icon: 'uil uil-apps'},
                    {title: 'قوانین',routeName: 'Terms',routeParams: "/",icon: 'uil uil-university'},
                    {title: 'گزارش خطا',routeName: 'Report',routeParams: "/",icon: 'uil uil-bug'},
                    {title: 'تماس با ما',routeName: 'Contact',routeParams: "/",icon: 'uil uil-envelope-alt'},
                ]},
                {title: 'تنظیمات',routeName: 'Settings',icon: 'uil uil-setting',children: []},
                {title: 'خروج',routeName: 'Logout',icon: 'uil uil-signout',children: []},

            ],
            sidebar_items: [{
                    title: 'نمایه کاربر',
                    routeName: 'Profile',
                    icon: 'uil uil-user',
                    // svg:'profile',
                    svg_h: '<svg  class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                    children: [],
                }, {
                    title: 'داشبوردها',
                    routeName: 'Dashboards',
                    icon: 'uil uil-create-dashboard',
                    // svg:'dashboards',
                    svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                    children: [],
                },
                //{
                //    title: 'سامانه معاملات',
                //    routeName: 'Exchange',
                //    icon: 'uil uil-create-dashboard',
                //    svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                //    children: [],
                //},
                {
                    title: 'سبد دارایی',
                    routeName: 'Investment',
                    icon: 'uil uil-bill',
                    // svg:'investment',
                    svg_h: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="tgcss-w-6 tgcss-ml-2">   <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" /> </svg>',
                    children: [],
                },
                // {
                // 	title: 'بازار‌ها',
                // 	routeName: 'MarketViews',
                // 	icon: 'uil uil-shop',
                // 	children: [],
                // },
                {
                    title: 'گفت‌وگوها',
                    routeName: 'Forums',
                    icon: 'uil uil-comment-alt-lines',
                    svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                    children: [],
                },
                // {
                //     title: 'بازار‌ها',
                //     routeName: 'MarketViews',
                //     icon: 'uil uil-shop',
                //     svg_h: '',
                //     children: [],
                // },
                {
                    title: 'تحلیل‌ها',
                    routeName: 'NewTechnical',
                    icon: 'uil uil-chart',
                    svg_h: '<svg class="tgcss-w-6 tgcss-ml-2"  data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                    children: [],
                },
                // {
                // 	title: 'تحلیل‌های من',
                // 	routeName: 'MyTechnical',
                // 	icon: 'uil uil-chart',
                // 	children: [],
                // },
                {
                    title: 'هشدارها',
                    routeName: 'AlertsView',
                    icon: 'uil uil-bell',
                    svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                    children: [],
                }, {
                    title: 'واچ لیست‌ها',
                    routeName: 'WatchListsView',
                    icon: 'uil uil-list-ui-alt',
                    svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" stroke-linecap="round" stroke-linejoin="round"></path>   <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                    children: [],
                }, {
                    title: 'بوک‌مارک‌ها',
                    routeName: 'FavoritesView',
                    icon: 'uil uil-bookmark-full',
                    svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                    children: [],
                },
                // {
                // 	title: 'جدول تقویم اقتصادی ',
                // 	routeName: 'CalendarTable',
                // 	icon: 'uil uil-calendar-slash',
                // 	children: [],
                // },
                // {
                //     title: 'تقویم اقتصادی ',
                //     routeName: 'CalendarView',
                //     // icon: 'uil uil-calendar-alt',
                //     svg_h: '<svg class="rightnav-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path class="fa-primary" d="M160 64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V192H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32V64z"/><path class="fa-secondary" d="M0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM64 304C64 312.8 71.16 320 80 320H112C120.8 320 128 312.8 128 304V272C128 263.2 120.8 256 112 256H80C71.16 256 64 263.2 64 272V304zM208 256C199.2 256 192 263.2 192 272V304C192 312.8 199.2 320 208 320H240C248.8 320 256 312.8 256 304V272C256 263.2 248.8 256 240 256H208zM320 304C320 312.8 327.2 320 336 320H368C376.8 320 384 312.8 384 304V272C384 263.2 376.8 256 368 256H336C327.2 256 320 263.2 320 272V304zM80 384C71.16 384 64 391.2 64 400V432C64 440.8 71.16 448 80 448H112C120.8 448 128 440.8 128 432V400C128 391.2 120.8 384 112 384H80zM192 432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V400C256 391.2 248.8 384 240 384H208C199.2 384 192 391.2 192 400V432zM336 384C327.2 384 320 391.2 320 400V432C320 440.8 327.2 448 336 448H368C376.8 448 384 440.8 384 432V400C384 391.2 376.8 384 368 384H336z"/></svg>',
                //     children: [],
                // },
                {
                    title: 'وظایف روزانه',
                    routeName: 'TodoView',
                    icon: 'uil uil-list-ol-alt',
                    svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                    children: [],
                }, {
                    title: 'یادداشت‌ها و اسناد',
                    routeName: 'NotesView',
                    icon: 'uil uil-notes',
                    svg_h: '<svg class="tgcss-w-6 tgcss-ml-2"  data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                    children: [],
                },{
                    title: 'حساب کاربری',
                    routeName: 'ProfileEdit',
                    icon: 'uil uil-user-square',
                    svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                    children: [],
                },
                // {
                //     title: 'حساب کاربری',
                //     routeName: 'ProfileEdit',
                //     icon: 'uil uil-user-square',
                //     svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                //     children: [
                //         {
                //             title: 'مشخصات کاربری',
                //             routeName: 'Investment6',
                //             routeParams: "/",
                //             svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                //         },
                //         {
                //             title: 'حساب‌های بانکی',
                //             routeName: 'Investment7',
                //             routeParams: "/",
                //             svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                //         },
                //         {
                //             title: 'احراز هویت',
                //             routeName: 'Investment8',
                //             routeParams: "/",
                //             svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M7.864 4.243A7.5 7.5 0 0 1 19.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 0 0 4.5 10.5a7.464 7.464 0 0 1-1.15 3.993m1.989 3.559A11.209 11.209 0 0 0 8.25 10.5a3.75 3.75 0 1 1 7.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 0 1-3.6 9.75m6.633-4.596a18.666 18.666 0 0 1-2.485 5.33" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                //         },
                //         {
                //             title: 'امنیت حساب',
                //             routeName: 'Investment9',
                //             routeParams: "/",
                //             svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                //         },
                //         {
                //             title: 'تنظیملت حساب',
                //             routeName: 'Investment10',
                //             routeParams: "/",
                //             svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" stroke-linecap="round" stroke-linejoin="round"></path>   <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                //         },
                //         {
                //             title: 'اعلانات و اطلاعیه‌ها',
                //             routeName: 'Investment11',
                //             routeParams: "/",
                //             svg_h: '<svg class="tgcss-w-6 tgcss-ml-2" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">   <path d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" stroke-linecap="round" stroke-linejoin="round"></path> </svg>',
                //         },
                //     ],
                // },
                // {
                // 	title: 'ویجت‌ها',
                // 	routeName: 'WidgetsView',
                // 	icon: 'uil uil-notes',
                // 	children: [],
                // }
                // {
                //     title: 'خروج',
                //     routeName: 'Logout',
                //     // icon: 'uil uil-signout',
                //     svg_h: '<svg class="rightnav-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="fa-primary" d="M502.6 278.6l-128 128c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L402.8 288H192C174.3 288 160 273.7 160 256s14.31-32 32-32h210.8l-73.38-73.38c-12.5-12.5-12.5-32.75 0-45.25c12.49-12.49 32.74-12.51 45.25 0l128 128C515.1 245.9 515.1 266.1 502.6 278.6z"/><path class="fa-secondary" d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416z"/></svg>',
                //     children: [],
                // }
            ],
            // notifications_open: true,
            notifications: [],
            menu: 1,
            subMenu: 0,
            activeNav: null,
            activeNavData: null,
            activeSubNav: null,
            desktop: false,
            alertsCount:0,
        }
    },
    mounted() {
        // this.notifications_open = true;
        this.$helpers.replace_rel_to_abs('.bar-menu ul:not(.user-sidebar-profile-list) a');
        // کال کردن ایجاد منوی داشبورد های اختصاصی که کاربر ساخته است
        // this.setDashboards(this.$store.state.dashboards);
        if (Vue.prototype.$helpers.checkDevice('desktop')) {
            this.desktop= true;
            // this.getAlertsCount();
            this.sidebar_items.splice(1, 0, {title: 'بازار‌ها',routeName: 'MarketRedirect',icon: 'uil uil-shop',svg_h: '',children: []});
            this.sidebar_items.splice(0, 0, {title: 'خانه',routeName: 'HomeIntro',icon: 'uil uil-estate',svg_h: '',children: []});
            // this.sidebar_items.splice(20, 0, {title: 'خروج',routeName: 'Logout',icon: 'uil uil-signout',svg_h: '',children: []});
        }else{
            this.desktop= false;
        }

        
    },
    computed: {
        // dashboards_sync() {
        //     return this.$store.state.dashboards;
        // },
        current_route() {
            return this.$store.state.currentRoute;
        },
    },
    methods: {
        miniSidebar(){
            const div = document.querySelector('html');
            if(div.classList.contains('mini-desktop-menu')){
                $("html").removeClass('mini-desktop-menu');
            }else{
                $("html").addClass('mini-desktop-menu');
            }
        },
        // این متد برای شید کردن منوهای جنبی داشبورد می باشد
        activeNavToggle(index,item = null) {
            if (this.activeNav == index) {
                this.activeNav = null;
            } else {
                this.activeNav = index;
            }
            if(item != null){
                if(item == this.activeNavData){
                    this.activeNavData = null;
                }else{
                    if(item.children.length > 0){
                        this.activeNavData = item;
                        this.subMenu = 1;
                        this.menu = 0;
                    }else {
                        this.activeNavData = null;
                    }
                }
            } else {
                this.activeNavData = null;
            }
            $('.bar-menu-dashboard').removeClass('show-menu-mobile');
        },
        // این متد برای شید کردن ساب منو های جنبی داشبورد می باشد
        activeSubNavToggle(index) {
            if (this.activeSubNav == index) {
                this.activeSubNav = null;
            } else {
                this.activeSubNav = index;
            }
        },


        // getAlertsCount() {
        //     this.$helpers
        //     .makeRequest("GET", "/alert/alerts-count")
        //     .then((api_response) => {
        //         if (api_response.status == 200) {
        //             this.alertsCount = api_response.data.response.count;
        //         } 
        //     });
        // },

        // این متد برای هندل کردن ساب منو های داشبورد های کاربر میباشد
        // setDashboards(menu) {
        //     let _dashboards = menu ? menu : JSON.parse(localStorage.getItem('dashboards'));
        //     let dashboards = [];
        //     dashboards = [];

        //     _dashboards.forEach(dashboard => {
        //         dashboards.push({
        //             title: dashboard.title,
        //             routeName: 'Dashboard',
        //             routeParams: { id: dashboard.id },
        //             icon: 'uil uil-angle-left',
        //         });

        //         // check if we are in end of _dashboards
        //         if (_dashboards.indexOf(dashboard) == _dashboards.length - 1) {
        //             dashboards.push({
        //                 title: 'مدیریت داشبورد‌ها',
        //                 routeName: 'Dashboards',
        //                 routeParams: "/",
        //                 icon: 'uil uil-list-ol-alt',
        //             });

        //             // reverse array
        //             dashboards.reverse();
        //         }
        //     });

        //     if (this.sidebar_items) {
        // 		this.sidebar_items[1].children = dashboards;
        // 	}
        // },
        // open_auth_modal() {
        // 	getAuth();
        // },
        // triggerNotifications(action = null) {
        // 	this.notifications_open = ( action == 'open' ) ? true : false;
        // },
    },
    // events: {
    // 	triggerNotifications(action = null) {
    // 		this.notifications_open = ( action == 'open' ) ? true : false;
    // 	}
    // },
    directives: {
        ClickOutside
    },
    // در متد واچ 
    watch: {
        // dashboards_sync: {
        //     handler: function (dashboards_sync, oldVal) {
        //         this.setDashboards(this.$store.state.dashboards);
        //     },
        //     deep: true
        // },
        current_route: {
            handler: function (current_route, oldVal) {},
            deep: true
        },
    },
}
</script>
